@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700&display=swap");

html,
body,
#root {
	height: 100%;
	width: 100%;
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "Red Hat Display", sans-serif;
}
