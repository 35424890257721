#account_selector_shadow {
	box-shadow: 1px 0px 10px 0px rgba(0, 0, 0, 0.64);
	-webkit-box-shadow: 1px 0px 10px 0px rgb(205, 205, 255);
	-moz-box-shadow: 1px 0px 10px 0px rgba(0, 0, 0, 0.64);
}

#components-form-demo-normal-login .login-form {
	max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
	float: right;
}
#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
	float: left;
}
#components-form-demo-normal-login .login-form-button {
	width: 100%;
}

.courses_container::-webkit-scrollbar {
	display: none;
}
